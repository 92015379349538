export default class extends Controller {
  static targets = ['closeButton']

  connect() {
    if (Notification.permission !== "granted") {
      this.element.classList.remove('d-none')
    }
  }

  async allowWebpushNotifications(){
    const allowNotificationElement = event.currentTarget
    const result = await window.Notification.requestPermission()

    if (result === "granted") {
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        serviceWorkerRegistration.pushManager.subscribe({
          applicationServerKey: new Uint8Array([4, 202, 33, 133, 38, 108, 117, 34, 191, 201, 16, 123, 199, 68, 232, 206, 120, 158, 130, 142, 181, 143, 228, 99, 35, 128, 208, 146, 250, 126, 194, 55, 83, 120, 231, 116, 53, 232, 210, 66, 42, 211, 237, 11, 133, 166, 159, 230, 199, 116, 122, 3, 222, 168, 103, 243, 172, 122, 247, 198, 66, 111, 115, 115, 141]),
          userVisibleOnly: true,
        }).then(async function(data){
          await fetch('/webpush_subscriptions',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          }).then(function(r) {
            r.json()
            allowNotificationElement.classList.add('d-none')
          })
        });
      })
    }
  }
}
