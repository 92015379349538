if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: './' }).then(function(reg) {
    console.log('[Companion]', 'Service worker registered!');

    if (Notification.permission === "granted") {
      subscribeNotifications()
    } else {
      const ua = window.navigator.userAgent;
      const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      const webkit = !!ua.match(/WebKit/i);
      const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

      const isIos = iOSSafari && !navigator.standalone

      if (!isIos) {
        subscribeNotifications()
      }
    }
  });
} else {
  console.error('Service worker is not supported in this browser');
}

function subscribeNotifications() {
  navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
    serviceWorkerRegistration.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey: new Uint8Array([4, 202, 33, 133, 38, 108, 117, 34, 191, 201, 16, 123, 199, 68, 232, 206, 120, 158, 130, 142, 181, 143, 228, 99, 35, 128, 208, 146, 250, 126, 194, 55, 83, 120, 231, 116, 53, 232, 210, 66, 42, 211, 237, 11, 133, 166, 159, 230, 199, 116, 122, 3, 222, 168, 103, 243, 172, 122, 247, 198, 66, 111, 115, 115, 141])
    }).then(async function(data){
      await fetch('/webpush_subscriptions',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }).then(r => r.json())
    });
  });
}
