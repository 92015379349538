export default class extends Controller {
  static targets = ['optionDiv', 'question', 'option']

   toggle(event) {
    const currentStarId = parseInt(event.currentTarget.id.split('-')[1]);

    // Iterate through all stars up to and including the current one
    for (let i = 1; i <= currentStarId; i++) {
        const starElement = document.getElementById('star-' + i);
        const checkbox = document.getElementById('checkbox-star-' + i);

        // Add 'mdi-star' and remove 'mdi-star-outline' for these stars
        document.getElementById('rate-star-' + i).classList.add('mdi-star');
        document.getElementById('rate-star-' + i).classList.remove('mdi-star-outline');

        // Check the corresponding checkboxes
        checkbox.checked = true;
    }

    // Iterate through all stars after the current one to uncheck them
    for (let i = currentStarId + 1; i <= 5; i++) {
        const starElement = document.getElementById('star-' + i);
        const checkbox = document.getElementById('checkbox-star-' + i);

        // Add 'mdi-star-outline' and remove 'mdi-star' for these stars
        document.getElementById('rate-star-' + i).classList.add('mdi-star-outline');
        document.getElementById('rate-star-' + i).classList.remove('mdi-star');

        // Uncheck the corresponding checkboxes
        checkbox.checked = false;
    }
  }

  addMultipleOption(){
    if (event.currentTarget.value == 'multiple_choice'){
      event.currentTarget.parentElement.parentElement.parentElement.querySelector('[data-survey-target="optionDiv"]').classList.remove('d-none');
    }else{
      event.currentTarget.parentElement.parentElement.parentElement.querySelector('[data-survey-target="optionDiv"]').classList.add('d-none');
    }
  }

  addQuestionForm(event) {
    event.preventDefault();
    // Ensure we get the correct container to append the new question form
    const container = document.getElementById("question-form-container");

    // Get the template content
    const questionFormTemplate = document.getElementById('question_form').innerHTML;

    // Create a new div for the new question form
    var questionIndex = document.querySelectorAll("div[id^='question_form']").length;
    const divId = "question_form_" + (container.children.length + 1);
    const newQuestionForm = document.createElement("div");
    newQuestionForm.id = divId;
    newQuestionForm.setAttribute('data-controller', 'survey')
    newQuestionForm.className = "card custom-card position-relative";
    newQuestionForm.innerHTML = questionFormTemplate;


    const optionDiv = newQuestionForm.querySelector('[data-survey-target="optionDiv"]');
    if (optionDiv) {
      const optionDivChildren = Array.from(optionDiv.children);
      optionDivChildren.forEach((child, index) => {
          if (index > 0) {
              child.remove();
          }
      });
    }
    newQuestionForm.querySelectorAll('input, select, textarea').forEach(function(input) {
      input.name = input.name.replace(/\d+/, questionIndex);
      input.id = input.id.replace(/\d+/, questionIndex);
      if(input.type != 'select-one'){
        input.value = '';
      }else{
        input.value = 'text'
      }
      if(input.type == 'text' && input.name != "survey[questions_attributes][" + questionIndex + "][question_options_attributes][0][option_text]"){
        input.remove();
      }
      if(input.type == 'hidden' && !input.name.includes('[_destroy]') && !input.name.includes('[position]')){
        input.remove();
      }
    });
    newQuestionForm.querySelector('[data-survey-target="optionDiv"]').className = 'd-none'


    var questionCard =newQuestionForm
    var addButtons = questionCard.querySelectorAll('.add-button-container');

    // Get the last ID of the "Add Question" button
    addButtons.forEach(function(button, index) {
      var nextId = index + 1;
      button.id = 'add-question-btn-' + nextId;
    });
    const currentQuestionForm = event.currentTarget.closest("div[id^='question_form']")
    if (event.currentTarget.parentNode.previousElementSibling.classList.contains('question-close-button')){
      currentQuestionForm.insertAdjacentElement('beforebegin', newQuestionForm);
    }else{
      currentQuestionForm.insertAdjacentElement('afterend', newQuestionForm);
    }

    this.updateQuestionPosition(event);
  }

  removeQuestionForm(event) {
    let currentElement = event.currentTarget.parentElement.parentElement
    currentElement.parentElement.querySelector('.destroy-stimulus').value = 1
    currentElement.remove()
    this.updateQuestionPosition(event);
  }

  updateQuestionPosition(event){
    document.querySelectorAll("div[id^='question_form']").forEach((target, index) => {
      target.querySelector('.position-stimulus').value = index;
      target.querySelector('.question-number').textContent = index + 1;
    });
  }

  addOption(event){
    event.preventDefault();

    // Ensure we get the correct container to append the new question form
    const container = document.getElementById("options-container");

    // Get the template content
    const option_Template = event.currentTarget.closest("div[id^='option_form']").innerHTML;

    const divId = "option_form-" + (container.children.length + 1);
    const newOption = document.createElement("div");
    newOption.id = divId;
    newOption.className = "row option-div"
    newOption.innerHTML = option_Template;
    var optionIndex = this.optionDivTarget.querySelectorAll("div[id^='option_form']").length

    newOption.querySelectorAll('input, select, textarea').forEach(function(input) {
      input.name = input.name.replace(/\[question_options_attributes\]\[\d+\]/, '[question_options_attributes][' + optionIndex + ']');
      input.id = input.id.replace(/\d+/, optionIndex);
      input.value = '';
    });

    var optionCard =newOption
    var addButtons = optionCard.querySelectorAll('.add-option-button-container');

    // Get the last ID of the "Add Question" button
    addButtons.forEach(function(button, index) {
      var nextId = index + 1;
      button.id = 'add-option-btn-' + nextId;
    });


    // Find the current add button
    const currentoptionForm =  event.currentTarget.closest("div[id^='option_form']");
    if(event.currentTarget.parentNode.nextElementSibling.classList.contains('cross-option-button')){
      // Insert the new question form right after the current add button
      currentoptionForm.insertAdjacentElement('afterend', newOption);
    }else{
      currentoptionForm.insertAdjacentElement('beforebegin', newOption);
    }
    this.updateOptionPosition(event);
  }

  updateOptionPosition(event) {
    this.optionDivTarget.querySelectorAll("div[id^='option_form']").forEach((target, index) => {
      target.querySelector('.input-option').name.replace(/\[question_options_attributes\]\[\d+\]/, '[question_options_attributes][' + index + ']');
    });
  }


  removeOption(event){
    let currentElement = event.currentTarget.parentElement.parentElement
    currentElement.parentElement.querySelector('.destroy-option-stimulus').value = 1
    currentElement.remove();
  }

}
