export default class extends Controller {

  initialize() {
    this.loggingOut = false;

    document.querySelector('.log-out').addEventListener('click', this.handleLogout.bind(this));
    document.addEventListener('keydown', this.handleVisibilityChange.bind(this));
    document.addEventListener('click', this.handleVisibilityChange.bind(this));
  }

  handleLogout() {
   this.loggingOut = true;
  }

  handleVisibilityChange() {
    if (!this.loggingOut) {
      const url = `/user_info`;
      fetch(url, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response =>response.json())
      .then(data => {
        if(!data.authorized){
          window.location.href = '/';
        }
      })
    }
  }
}
